<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Функции</v-card-title>
        <v-card-text class="mt-4" style="display: flex;flex-wrap: wrap; gap: 20px">
          <v-card max-width="300px" v-for="feature in features" :style="feature.cities.length ?'':'opacity:1'">
            <v-card-title style="word-break: normal;">
              <div :contenteditable="true" @input="event => feature.title = event.target.innerHTML.trim() || 'Напишите описание'" @focusout="saveFeature(feature)">{{feature.title}}</div>
            </v-card-title>
            <v-card-subtitle>
              <template v-if="feature.cities.length"><InfoTip position="right" :text="`Включено в ${feature.cities.length} городах`" :value="feature.cities.map(c => $store.state.getCityById(c).city).join(', ')"/></template>
              <template v-else>Выключено {{feature.last_disable.date ? $root.dateToRus(feature.last_disable.date) : '---'}}</template>
            </v-card-subtitle>
            <v-card-text>
              <v-select
                  multiple
                  outlined
                  dense
                  placeholder="Города"
                  :items="$store.state.toSelectItems($store.state.user.allCities, 'city', 'id')"
                  v-model="feature.cities"
                  @change="saveFeature(feature)"
              >
                <template v-slot:selection="{ item, index }">
                  <template v-if="index < 5">
                    <span class="mr-1 pt-1">{{ item.text }}{{feature.cities.length-1 === index ? '' : ','}} </span>
                  </template>
                  <template v-if="index === 5">
                    <v-chip small class="ml-2">+{{ feature.cities.length - 5 }}</v-chip>
                  </template>
                </template>
              </v-select>
              <div style="display: flex;gap: 10px;flex-wrap: wrap;margin-top: 10px">
                <v-btn @click="feature.cities = $store.state.user.allCities.map(c=>c.id);saveFeature(feature)" small>Включить везде</v-btn>
                <v-btn @click="feature.cities = feature.cities.concat($store.state.user.allCities.filter(c=>+c.country===1).map(c=>c.id));saveFeature(feature)" small>Включить в РФ</v-btn>
                <v-btn @click="feature.cities = feature.cities.concat($store.state.user.allCities.filter(c=>+c.owner_is_msk).map(c=>c.id));saveFeature(feature)" small>Включить в московских филиалах</v-btn>
                <v-btn :disabled="!feature.cities.length" @click="feature.cities = [];saveFeature(feature)" small class="v-btn-danger">Выключить везде</v-btn>
                <v-btn :disabled="!feature.cities.length" @click="feature.cities = feature.cities.filter(id=>!$store.state.user.allCities.filter(c=>+c.country===1).map(c=>+c.id).includes(+id));saveFeature(feature)" small class="v-btn-danger">Выключить в РФ</v-btn>
                <v-btn :disabled="!feature.cities.length" @click="feature.cities = feature.cities.filter(id=>!$store.state.user.allCities.filter(c=>+c.owner_is_msk).map(c=>+c.id).includes(+id));saveFeature(feature)" small class="v-btn-danger">Выключить в московских филиалах</v-btn>
              </div>
              <v-expansion-panels v-if="feature.description" flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>Описание</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div :contenteditable="true" @input="event => feature.description = event.target.innerHTML" @focusout="saveFeature(feature)" v-html="feature.description"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import InfoTip from "../../components/InfoTip.vue";

export default {
  name: "Features",
  components: {InfoTip},
  data() {
    return {
      cities: [],
      features: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store.state.server.request('features/get', {}, (data) => {
        this.features = data.response;
      });
    },
    saveFeature(feature) {
      this.$store.state.server.request('features/update/' + feature.id, feature);
    }
  }
}
</script>
